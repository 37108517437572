import React, { useEffect, useState } from 'react'
import typoStyles from '../../Styles/component/Typography.module.css'
import { useNavigate } from 'react-router-dom'
import {
    Button,
    Checkbox,
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Toolbar,
    Tooltip,
} from '@mui/material'
import moment from 'moment'
import { useTaskmanager } from '../../Services/taskManagerService/taskManagerService'
import TableHeader from '../TableHeader'
import TaskNoRecord from '../TaskNoRecord/TaskNoRecord'
import _ from 'lodash'
import { StatusTag } from '../../Pages/TaskManager/JobManagerMUIStyles'
import {
    getEncounterData,
    startTask,
} from '../../Services/api/auditorAPIService'
import { useTableService } from '../../Services/taskManagerService/tableService'
import TableThreeDotMenu from '../TableThreeDotMenu/TableThreeDotMenu'
import MessageIcon from '../../Assests/Icons/MessageIcon.png'

interface TATMeterAuditorIndividual {
    id?: string
    check?: boolean
    status?: any
    location?: string
    city?: string
    providerName?: string
    site?: string
    startDate?: Date
    taskName?: string
    audioReceived?: Date
    galenTime?: string
    auditorTAT?: string
    totalTAT?: string
}
function IndividualAuditor({ id, trigger }: any) {
    const {
        sortingObj,
        bubblechartData,
        barchartDataCount,
        barchartData,
        chartFilter,
        filterMenuChange,
        filterOnChange,
        filterSearchByField,
        searchByName,
        auditors,
        updatedTaskList,
        closeTaskDrawer,
        task,
        addendumIndex,
        // aTask,
        enableModel,
        name,
        openFilter,
        filterFlag,
        pagination,
        setPagination,
        taskList,
        filteredValue,
        filterdHeader,
        handleRequestSort,
        openDrawer,
        openModel,
        modalTranscriptClose,
        transcriptmodal,
        openTranscriptModel,
        openFillablePdfModel,
        fillablePdfmodal,
        openDocuemt3PdfModel,
        encounterData,
        addendumEncounterData,
        statusCount,
        filterButton,
        setFilterButton,
    } = useTaskmanager()
    const navigate = useNavigate()
    const [chatnavigation, setFlagShowMsg] = useState<any>(null)
    const [checkedList, setCheckedList] = useState<Array<string>>([])
    const [encounterpause, setEncounterPause] = useState<boolean>(false)

    const [testData] = useState<Array<TATMeterAuditorIndividual>>([
        {
            id: '1',
            status: 'Ready_for_Audit',
            location: 'CC',
            city: 'CS',
            providerName: 'PN',
            site: 'SOS',
            startDate: new Date('July 17, 2024'),
            taskName: 'ROV',
            audioReceived: new Date('July 19, 2024'),
            galenTime: '2 min',
            auditorTAT: '5 min',
            totalTAT: '7 min',
        },
        {
            id: '2',
            status: 'Audit_in_Progress',
            location: 'CC2',
            city: 'CS2',
            providerName: 'PN2',
            site: 'SOS2',
            startDate: new Date('July 17, 2024'),
            taskName: 'ROV',
            audioReceived: new Date('July 20, 2024'),
            galenTime: '3 min',
            auditorTAT: '6 min',
            totalTAT: '9 min',
        },
    ])
    useEffect(() => {
        if (checkedList.length > 0) {
            trigger(true)
        } else {
            trigger(false)
        }
    }, [checkedList])

    const { selectField } = useTableService({
        from: 'IndividualAuditor',
    })
    const handleClick2 = (row?: any, addendumRow?: any) => {
        setEncounterPause(true)
        if (addendumIndex != null) {
            gatherEncounterData(row.encounterId, addendumRow.id)
            callStartTask()
        } else {
            gatherEncounterData(row.encounterId)
            callStartTask()
        }
    }
    const gatherEncounterData = async (
        encounterId: any,
        addendumEncounterId?: any,
        row?: any,
        addendumRow?: any,
    ) => {
        try {
            const resp = await getEncounterData({ encounterId: encounterId })
            // setEncounterPause(true)
            if (addendumEncounterId) {
                const resp2 = await getEncounterData({
                    encounterId: addendumEncounterId,
                })
                setEncounterPause(false)

                navigate('/coding', {
                    state: {
                        patientData: addendumRow,
                        encounterData: resp2.encounterData,
                        addendumIndex: addendumIndex,
                        addendum: true,
                        original: row,
                        originalData: resp.encounterData,
                    },
                })
            } else {
                setEncounterPause(false)

                navigate('/coding', {
                    state: {
                        patientData: row,
                        encounterData: resp.encounterData,
                    },
                })
                setEncounterPause(false)
            }
        } catch (err) {
            console.log('encounter data error', err)
        }
    }
    const callStartTask = async (
        addendumIndex?: any,
        addendumRow?: any,
        row?: any,
    ) => {
        try {
            if (addendumIndex != null) {
                const start = await startTask({
                    encounterId: addendumRow.id,
                })
                // console.log('start1', start)
            } else {
                const start = await startTask({ encounterId: row.encounterId })
                // console.log('start2', start)
            }
        } catch (err) {
            console.log('calling start task failed', err)
        }
    }
    const handleCheck = (data: any) => {
        if (!_.includes(checkedList, data.id)) {
            setCheckedList([...checkedList, data.id])
        } else {
            setCheckedList((prevChecked: any) => {
                return prevChecked.filter(
                    (checkedList: any) => checkedList !== data.id,
                )
            })
        }
    }

    // console.log('data', testData, id)
    // console.log('checked', checkedList)

    return (
        <>
            <TableContainer sx={{ height: '70vh' }}>
                <Table
                    stickyHeader
                    // sx={{ width: '10dvw', borderBlock: 'black' }}
                    aria-label="simple table"
                >
                    <TableHeader
                        sortingObj={sortingObj}
                        handleRequestSort={handleRequestSort}
                        from={'IndividualAuditor'}
                    />
                    <TableBody>
                        {taskList?.map((data: any, index: number) => {
                            return (
                                <TableRow key={index + 'testdata'}>
                                    {Object.values(selectField).map(
                                        (item: any, index2: number) => {
                                            if (index2 == 0) {
                                                return (
                                                    <TableCell
                                                        key={'check' + index}
                                                        size="small"
                                                        sx={{
                                                            border: '0.5px solid #A0A0A0',
                                                            whiteSpace: 'wrap',
                                                        }}
                                                    >
                                                        <Checkbox
                                                            checked={_.includes(
                                                                checkedList,
                                                                data.id,
                                                            )}
                                                            onChange={() =>
                                                                handleCheck(
                                                                    data,
                                                                )
                                                            }
                                                        />
                                                    </TableCell>
                                                )
                                            } else {
                                                return (
                                                    <BodyField
                                                        key={
                                                            index +
                                                            item.key +
                                                            ':original'
                                                        }
                                                        openDrawer={openDrawer}
                                                        name={item.key}
                                                        row={data}
                                                        // modalOpen={modalOpen}
                                                        // openTranscriptModel={
                                                        //     openTranscriptModel
                                                        // }
                                                        // openFillablePdfModel={
                                                        //     openFillablePdfModel
                                                        // }
                                                        // openDocuemt3PdfModel={
                                                        //     openDocuemt3PdfModel
                                                        // }
                                                        setFlagShowMsg={
                                                            setFlagShowMsg
                                                        }
                                                        // border={
                                                        //     data.displayStatus ==
                                                        //     'Addendum_Merged'
                                                        //         ? '2px solid black'
                                                        //         : ''
                                                        // }
                                                        // borderbottom={
                                                        //     data
                                                        //         ?.addendumEncounters
                                                        //         ?.length != 0
                                                        //         ? '12px solid black'
                                                        //         : ''
                                                        // }
                                                        // borderLeft={
                                                        //     data
                                                        //         .addendumEncounters
                                                        //         ?.length != 0
                                                        //         ? '12px solid black'
                                                        //         : ''
                                                        // }
                                                    />
                                                )
                                            }
                                        },
                                    )}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                <TaskNoRecord record={testData} />
            </TableContainer>
        </>
    )
}
type TableHeaderFieldProps = {
    name?: string
    row?: any
    encounterData?: any
    openDrawer?: any
    setFlagShowMsg?: any
    addendumRow?: any
    addendumIndex?: any
    border?: any
    borderbottom?: any
    borderLeft?: any
}
const BodyField = ({
    row,
    // encounterData,
    name,
    openDrawer,
    setFlagShowMsg,
    addendumRow,
    addendumIndex,
    border,
    borderbottom,
    borderLeft,
}: TableHeaderFieldProps) => {
    const [encounterData, setEncounterData] = useState<any>()
    const [encounterpause, setEncounterPause] = useState<boolean>(false)
    const navigate = useNavigate()
    // console.log('row', row)
    // console.log('managername', name)

    const handleClick = () => {
        // console.log('rowstatus', row.displayStatus != 'Processing')
        if (
            row.displayStatus != 'Processing' ||
            row.displayStatus != 'Scheduled'
        ) {
            if (addendumRow) {
                openDrawer(0, row, addendumIndex)
            } else {
                openDrawer(0, row)
            }
        }
    }
    const handleClick2 = () => {
        setEncounterPause(true)
        if (addendumIndex != null) {
            gatherEncounterData(row.encounterId, addendumRow.id)
            callStartTask()
        } else {
            gatherEncounterData(row.encounterId)
            callStartTask()
        }
    }
    const gatherEncounterData = async (
        encounterId: any,
        addendumEncounterId?: any,
    ) => {
        try {
            const resp = await getEncounterData({ encounterId: encounterId })
            // setEncounterPause(true)
            if (addendumEncounterId) {
                const resp2 = await getEncounterData({
                    encounterId: addendumEncounterId,
                })
                setEncounterPause(false)

                navigate('/coding', {
                    state: {
                        patientData: addendumRow,
                        encounterData: resp2.encounterData,
                        addendumIndex: addendumIndex,
                        addendum: true,
                        original: row,
                        originalData: resp.encounterData,
                    },
                })
            } else {
                setEncounterPause(false)

                navigate('/coding', {
                    state: {
                        patientData: row,
                        encounterData: resp.encounterData,
                    },
                })
                setEncounterPause(false)
            }
        } catch (err) {
            console.log('encounter data error', err)
        }
    }
    const callStartTask = async (addendumIndex?: any) => {
        try {
            if (addendumIndex != null) {
                const start = await startTask({
                    encounterId: addendumRow.id,
                })
                // console.log('start1', start)
            } else {
                const start = await startTask({ encounterId: row.encounterId })
                // console.log('start2', start)
            }
        } catch (err) {
            console.log('calling start task failed', err)
        }
    }

    if (row) {
        switch (name) {
            case 'status':
                // console.log('addendum', row)
                return (
                    <TableCell
                        size="small"
                        align="center"
                        sx={{
                            // whiteSpace: 'nowrap',
                            border: '0.5px solid #A0A0A0',
                            borderTop: border,
                            borderBottom: borderbottom,
                            borderLeft: borderLeft,
                        }}
                        className={typoStyles.typoRobotoRegoularprimary714}
                        // onClick={handleClick}
                    >
                        {addendumRow ? (
                            <div
                                style={{
                                    display: 'inline-flex',
                                }}
                            >
                                {/* <>{console.log('hasaddendum', addendumRow)}</> */}
                                {addendumRow.displayStatus ==
                                    'Addendum_Ready_for_Audit' ||
                                addendumRow.displayStatus ==
                                    'Addendum_in_Progress' ||
                                addendumRow.displayStatus ==
                                    'Addendum_Merged' ||
                                addendumRow.displayStatus ==
                                    'Addendum_Completed' ? (
                                    <div style={{ display: 'flex' }}>
                                        <Button
                                            aria-label="addendumCodingButton"
                                            // disabled={
                                            //     encounterData?.editedProgressNote ==
                                            //     null
                                            // }
                                            style={{
                                                textTransform: 'capitalize',
                                                // width: '5vw',
                                            }}
                                            onClick={() => {
                                                // console.log('click row', row)
                                                // handleClick2()
                                                // callStartTask(addendumIndex)
                                            }}
                                            // variant="contained"
                                        >
                                            <div
                                                style={{
                                                    display: 'inline-flex',
                                                    width: '5dvw',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <StatusTag
                                                    status={
                                                        addendumRow.displayStatus
                                                    }
                                                />
                                            </div>
                                        </Button>
                                    </div>
                                ) : (
                                    <div style={{ display: 'inline-flex' }}>
                                        <StatusTag
                                            status={addendumRow.displayStatus}
                                        />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div style={{ display: 'inline-flex' }}>
                                <>
                                    {/* {console.log(
                                        'hasnoaddendum',
                                        row.encounterAllData.displayStatus,
                                    )} */}
                                </>
                                {row.displayStatus == 'Ready_for_Audit' ||
                                row.displayStatus == 'Audit_in_Progress' ||
                                row.displayStatus == 'Validated_Record' ||
                                row.displayStatus == 'Validated_Coding' ||
                                row.displayStatus ==
                                    'Valicated_Record_Coding' ||
                                row.displayStatus == 'Audit_Escalated' ||
                                row.displayStatus == 'Sent_to_Interface' ||
                                row.displayStatus == 'Addendum_Merged' ||
                                row.displayStatus == 'Completed' ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            // width: '10dvw',
                                        }}
                                    >
                                        <Button
                                            aria-label="originalCodingButton"
                                            style={{
                                                textTransform: 'capitalize',
                                            }}
                                            onClick={() => {
                                                // setEncounterPause(true)
                                                // handleClick2()
                                            }}
                                            // variant="contained"
                                        >
                                            <div
                                                style={{
                                                    display: 'inline-flex',
                                                }}
                                            >
                                                <StatusTag
                                                    status={row?.displayStatus}
                                                />
                                            </div>
                                        </Button>
                                        {/* <CircularProgress size="30px" /> */}
                                        {encounterpause ? (
                                            <CircularProgress size="20px" />
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                ) : (
                                    <div style={{ display: 'inline-flex' }}>
                                        <StatusTag
                                            status={row?.displayStatus}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </TableCell>
                )
            case 'location':
            case 'city':
            case 'providerName':
            case 'site':
            case 'primSepcialty':
            case 'taskName':
            case 'secSpecialty':
            case 'totalJobs':
                return (
                    <TableCell
                        size="small"
                        // align={
                        //     name ===
                        //         'taskName' ||
                        //     name ===
                        //         'orgTid' ||
                        //     name === 'mrn'
                        //         ? 'left'
                        //         : 'center'
                        // }
                        sx={{
                            border: '0.5px solid #A0A0A0',
                            whiteSpace: 'wrap',
                        }}
                    >
                        {row[name]}
                    </TableCell>
                )

            case 'startDate':
            case 'startTime':
            case 'createdAt':
                return (
                    <TableCell
                        size="small"
                        sx={{
                            border: '0.5px solid #A0A0A0',
                            whiteSpace: 'wrap',
                        }}
                    >
                        {row[name]
                            ? moment(row[name]).format('DD/MMM/yyyy')
                            : null}
                    </TableCell>
                )
            case 'galenTime':
            case 'auditorTAT':
            case 'totalTAT':
                return (
                    <TableCell
                        size="small"
                        sx={{
                            border: '0.5px solid #A0A0A0',
                            whiteSpace: 'wrap',
                        }}
                    >
                        {/* {row[name]
                            ? moment(row[name]).format('DD/MMM/yyyy')
                            : null} */}
                    </TableCell>
                )
            // Add other cases as needed
            default:
                return (
                    <TableCell
                        size="small"
                        align="right"
                        key={row.id}
                        sx={{
                            whiteSpace: 'nowrap',
                            border: '0.5px solid #A0A0A0',
                            borderTop: border,
                            borderBottom: borderbottom,
                            borderRight: borderLeft,
                        }}
                    >
                        <Toolbar
                            style={{ padding: 0, minHeight: 0 }}
                            sx={{ padding: 0, margin: 0 }}
                        >
                            <Tooltip title="Open Message">
                                <div>
                                    <IconButton
                                        size="small"
                                        sx={{
                                            width: 40,
                                            height: 40,
                                            fontSize: 20,
                                        }}
                                        onClick={() => {
                                            openDrawer(5, row)
                                            setFlagShowMsg(1)
                                        }}
                                    >
                                        <img
                                            src={MessageIcon}
                                            alt="message-icon"
                                            style={{ width: 30, height: 30 }}
                                        />
                                    </IconButton>
                                </div>
                            </Tooltip>
                            <TableThreeDotMenu
                                data={row}
                                onclick={openDrawer}
                            />
                        </Toolbar>
                    </TableCell>
                )
        }
    } else {
        return <></>
    }
}

export default IndividualAuditor
