import styles from './JobManager.module.css'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import {
    Button,
    IconButton,
    Grid,
    Tooltip,
    Pagination,
    Grid2,
    Modal,
    Snackbar,
    Alert,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import RightSideDrawer from './Component/RightSideDrawer'
import buttonStyles from '../../Styles/component/ButtonStyle.module.css'
import typoStyles from '../../Styles/component/Typography.module.css'
import AddIcon from '@mui/icons-material/Add'
import CustomDialog from '../../Components/CustomDialog'
import AddTask from './Component/AddTask'
import ViewTask from './Component/ViewTask'
import ReassignTask from './Component/ReassignTask/ReassignTask'
import MarkClaimAlert from './Component/MarkClaimAlert/MarkClaimAlert'
import EditTask from './Component/EditTask'
import TableHeader from '../../Components/TableHeader'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import TablePagination from '../../Components/TablePagination/TablePagination'
import {
    Search,
    SearchIconWrapper,
    StatusTag,
    StyledInputBase,
} from './JobManagerMUIStyles'
import CustomFilter from '../../Components/CustomFilter/CustomFilter'
import NewBubble from '../Charts/BubbeChartNew'
import BarchartComponent from '../BarchartComponent/BarchartComponent'
import CloseIcon from '@mui/icons-material/Close'
import TableBodyField from './Component/TableBodyField/TableBodyField'
import { useTableService } from '../../Services/taskManagerService/tableService'
import TaskStatusChange from './Component/TaskStatusChange/TaskStatusChange'
import SuccessAlert from '../../Components/SuccessAlert/SuccessAlert'
import { statusButtons, statusLabel } from '../../Services/utils/constant'
import { useTaskmanager } from '../../Services/taskManagerService/taskManagerService'
import TaskNoRecord from '../../Components/TaskNoRecord/TaskNoRecord'
import { UnreadCountProvider } from '../../Components/Unreadcount/UnreadMessageCount'
import ChatComponent from '../ChatComponent/ChatComponent'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import statuslabel from '../../Styles/component/StatusLabel.module.css'
import NewJobModal from './Component/NewJobModal/NewJobModal'

const JobManagerQ = () => {
    const {
        sortingObj,
        bubblechartData,
        barchartDataCount,
        barchartData,
        chartFilter,
        filterMenuChange,
        filterOnChange,
        filterSearchByField,
        searchByName,
        auditors,
        updatedTaskList,
        closeTaskDrawer,
        task,
        addendumIndex,
        // aTask,
        enableModel,
        name,
        openFilter,
        filterFlag,
        pagination,
        setPagination,
        taskList,
        filteredValue,
        filterdHeader,
        handleRequestSort,
        openDrawer,
        openModel,
        modalTranscriptClose,
        transcriptmodal,
        openTranscriptModel,
        openFillablePdfModel,
        fillablePdfmodal,
        openDocuemt3PdfModel,
        encounterData,
        addendumEncounterData,
        statusCount,
        filterButton,
        setFilterButton,
        setRefresh,
        refresh,
    } = useTaskmanager()
    const { selectField } = useTableService()
    const userRole = useSelector(
        (state: any) => state.persistReducers?.userReducer?.user?.userRole,
    )
    const adminandmanagerroles = ['auditormanager', 'auditoradmin']
    const [chatnavigation, setFlagShowMsg] = React.useState<any>(null)
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [toast, setToast] = useState<boolean>(false)
    const [toastMessage, setToastMessage] = useState('')

    const handleCloseToast = () => {
        setToast(false)
        setRefresh(!refresh)
    }

    // Column Definitions: Defines the columns to be displayed.
    // console.log('task', taskList)
    // console.log('encounterData', encounterData)
    return (
        <div className={styles.MainDiv}>
            <div className={styles.CustomCard}>
                <div className={styles.searchfieldDiv}>
                    <Snackbar
                        anchorOrigin={{
                            horizontal: 'right',
                            vertical: 'top',
                        }}
                        open={toast}
                        autoHideDuration={3000}
                        onClose={handleCloseToast}
                    >
                        {toastMessage == 'good' ? (
                            <Alert
                                onClose={handleCloseToast}
                                severity="success"
                                variant="filled"
                                sx={{ width: '100%' }}
                            >
                                Successfully created the new claim
                            </Alert>
                        ) : toastMessage == 'bad' ? (
                            <Alert
                                onClose={handleCloseToast}
                                severity="error"
                                variant="filled"
                                sx={{ width: '100%' }}
                            >
                                There was a problem creating the new claim
                            </Alert>
                        ) : (
                            <div></div>
                        )}
                    </Snackbar>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <div>
                            <NewJobModal
                                setToastMessage={setToastMessage}
                                setToast={setToast}
                                handleCloseModal={handleClose}
                            />
                        </div>
                    </Modal>
                    <Grid2 container spacing={2} justifyContent="space-between">
                        <Grid2>
                            <div className={styles.tasklabeldiv}>
                                <span
                                    style={{ paddingLeft: '5px' }}
                                    className={
                                        typoStyles.typoRobotoMediumprimary262229
                                    }
                                >
                                    {'Jobs'}
                                </span>
                                {!filterFlag.showStatus &&
                                    !filterFlag.showAuditor && (
                                        <>
                                            {openFilter ? (
                                                <Tooltip title="Filter Tasks">
                                                    <IconButton
                                                        size="small"
                                                        onClick={
                                                            filterSearchByField
                                                        }
                                                    >
                                                        <FilterAltOutlinedIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            ) : (
                                                <CustomFilter
                                                    closeMenu={
                                                        filterSearchByField
                                                    }
                                                    selectField={selectField}
                                                    filterdHeader={
                                                        filterdHeader
                                                    }
                                                    handleMenuItemClick={
                                                        filterMenuChange
                                                    }
                                                    handleChangeFilter={
                                                        filterOnChange
                                                    }
                                                    filteredValue={
                                                        filteredValue
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                            </div>
                        </Grid2>
                        {/* <div>
                            <Button onClick={handleOpen}>Create New Job</Button>
                        </div> */}
                        <Grid2
                            style={{
                                display: 'flex',
                            }}
                        >
                            {statusButtons.map((buttons: any, index: any) => {
                                return (
                                    <div key={index}>
                                        <button
                                            className={
                                                statuslabel[buttons?.divPrimary]
                                            }
                                            style={{
                                                borderRadius: '50%',
                                                height: '95px',
                                                width: '95px',
                                                border: '1px solid black',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                textAlign: 'center',
                                                backgroundColor:
                                                    filterButton != ''
                                                        ? filterButton ==
                                                          buttons.key
                                                            ? ''
                                                            : '#ccc'
                                                        : '',
                                            }}
                                            onClick={() => {
                                                if (
                                                    filterButton == buttons.key
                                                ) {
                                                    setFilterButton('')
                                                } else {
                                                    setFilterButton(buttons.key)
                                                }
                                            }}
                                        >
                                            <div
                                                className={
                                                    typoStyles[
                                                        buttons?.typography
                                                    ]
                                                }
                                            >
                                                {buttons.label}
                                                <div>
                                                    {statusCount?.map(
                                                        (sCount: any) => {
                                                            if (
                                                                sCount.status ==
                                                                buttons.key
                                                            ) {
                                                                return sCount.count
                                                            }
                                                        },
                                                    )}
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                )
                            })}
                        </Grid2>
                        <Grid2>
                            <div className={styles.searchtextfield}>
                                {openFilter && (
                                    <Search>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            inputProps={{
                                                'aria-label': 'search',
                                            }}
                                            value={name}
                                            onChange={searchByName}
                                        />
                                    </Search>
                                )}
                                {adminandmanagerroles?.some(
                                    (item) => userRole === item,
                                ) && (
                                    <Button
                                        className={buttonStyles.primary1button}
                                        onClick={() => openDrawer(4, null)}
                                    >
                                        <AddIcon style={{ color: 'white' }} />
                                        &nbsp;&nbsp;
                                        <span
                                            className={
                                                typoStyles.typoRobotoMediumprimary214
                                            }
                                        >{`New Task`}</span>
                                    </Button>
                                )}
                                <Button onClick={handleOpen}>Import</Button>
                            </div>
                        </Grid2>
                    </Grid2>
                </div>
                {(filterFlag.showStatus || filterFlag.showAuditor) && (
                    <div className={styles.filterDiv}>
                        <Grid2 container spacing={2}>
                            <Grid2>
                                <span
                                    className={
                                        typoStyles.typoRobotoRegoularprimary31422
                                    }
                                >
                                    {'Showing Results for'}
                                </span>
                            </Grid2>
                            {filterFlag.showStatus &&
                                filterFlag.status?.map((item: any) => (
                                    <Grid2 key={item.id}>
                                        <div className={styles.filterBtn}>
                                            <span
                                                className={
                                                    typoStyles.typoRobotoRegoularprimary3814
                                                }
                                            >
                                                {statusLabel(item) + ' Tasks'}
                                            </span>
                                            <IconButton
                                                sx={{
                                                    width: '20px',
                                                    height: '20px',
                                                }}
                                                onClick={() =>
                                                    chartFilter(
                                                        item,
                                                        'status',
                                                        false,
                                                    )
                                                }
                                            >
                                                <CloseIcon
                                                    style={{
                                                        fontSize: '14px',
                                                        color: '#0059FF',
                                                    }}
                                                />
                                            </IconButton>
                                        </div>
                                    </Grid2>
                                ))}
                            {filterFlag.showAuditor &&
                                filterFlag?.auditor?.map((item: any) => (
                                    <Grid2 key={item.id}>
                                        {' '}
                                        <div className={styles.filterBtn}>
                                            <span
                                                className={
                                                    typoStyles.typoRobotoRegoularprimary3814
                                                }
                                            >
                                                {item?.auditorName.split(
                                                    ' ',
                                                )[0] + ' Tasks'}
                                            </span>
                                            <IconButton
                                                sx={{
                                                    width: '20px',
                                                    height: '20px',
                                                }}
                                                onClick={() =>
                                                    chartFilter(
                                                        item,
                                                        'auditor',
                                                        false,
                                                    )
                                                }
                                            >
                                                <CloseIcon
                                                    style={{
                                                        fontSize: '14px',
                                                        color: '#0059FF',
                                                    }}
                                                />
                                            </IconButton>
                                        </div>
                                    </Grid2>
                                ))}
                        </Grid2>
                    </div>
                )}

                {/* <div
                    className="ag-theme-quartz" // applying the Data Grid theme
                    style={{ height: 500, width: '100%' }} // the Data Grid will fill the size of the parent container
                >
                    <AgGridReact rowData={rowData} columnDefs={colDefs} />
                </div> */}
                <TableContainer sx={{ height: '70vh' }}>
                    <Table
                        stickyHeader
                        sx={{ minWidth: 750, borderBlock: 'black' }}
                        aria-label="simple table"
                    >
                        <TableHeader
                            sortingObj={sortingObj}
                            handleRequestSort={handleRequestSort}
                            from={'Q'}
                        />
                        <TableBodyField
                            filteredTask={taskList}
                            // addendumData={addendumEncounterData}
                            openDrawer={openDrawer}
                            modalOpen={openModel}
                            openTranscriptModel={openTranscriptModel}
                            setFlagShowMsg={setFlagShowMsg}
                            openFillablePdfModel={openFillablePdfModel}
                            openDocuemt3PdfModel={openDocuemt3PdfModel}
                            from={'Q'}
                        />
                    </Table>
                    <TaskNoRecord record={taskList} />
                </TableContainer>
                <RightSideDrawer
                    showShodow={true}
                    onClose={closeTaskDrawer}
                    openDrawer={
                        enableModel === 0 ||
                        enableModel === 1 ||
                        enableModel === 2 ||
                        enableModel === 3 ||
                        enableModel === 4 ||
                        enableModel === 5
                            ? true
                            : false
                    }
                >
                    {enableModel === 0 && (
                        <UnreadCountProvider>
                            <ViewTask
                                aIndex={addendumIndex}
                                task={task}
                                encounterData={encounterData}
                                addendumData={addendumEncounterData}
                                menuHandleCloseClick={closeTaskDrawer}
                                updatedTaskList={updatedTaskList}
                                openEdit={() => {
                                    openDrawer(1, task)
                                }}
                                showEditbtn={true}
                            />
                        </UnreadCountProvider>
                    )}
                    {enableModel === 1 && (
                        <EditTask
                            task={task}
                            menuHandleCloseClick={closeTaskDrawer}
                            updatedTaskList={updatedTaskList}
                            handleClosed={() => {
                                openDrawer(0, task)
                            }}
                        />
                    )}
                    {enableModel === 2 && (
                        <ReassignTask
                            task={task}
                            menuHandleCloseClick={closeTaskDrawer}
                            updatedTaskList={updatedTaskList}
                            auditorList={auditors}
                        />
                    )}
                    {enableModel === 3 && (
                        <TaskStatusChange
                            task={task}
                            menuHandleCloseClick={closeTaskDrawer}
                            updatedTaskList={updatedTaskList}
                        />
                    )}
                    {enableModel === 4 && (
                        <AddTask
                            menuHandleCloseClick={closeTaskDrawer}
                            updatedTaskList={updatedTaskList}
                            handleClosed={closeTaskDrawer}
                        />
                    )}
                    {enableModel === 5 && (
                        <ChatComponent
                            taskName={task?.taskName}
                            menuHandleCloseClick={closeTaskDrawer}
                            patientName={task?.patientName}
                            mrn={task?.mrn}
                            taskId={task.id}
                            providerId={task?.providerId}
                            auditorId={task.auditorId}
                            managerId={task.createdBy}
                            appointmentId={task.appointmentId}
                            chatnavigation={chatnavigation}
                            setFlagShowMsg={setFlagShowMsg}
                        />
                    )}
                </RightSideDrawer>
                <CustomDialog
                    open={enableModel === 6 || enableModel === 7 ? true : false}
                    handleClick={closeTaskDrawer}
                >
                    {enableModel === 6 && (
                        <MarkClaimAlert
                            task={task}
                            menuHandleCloseClick={closeTaskDrawer}
                            updatedTaskList={updatedTaskList}
                        />
                    )}
                    {enableModel === 7 && (
                        <SuccessAlert
                            conFirmOnClick={closeTaskDrawer}
                            primaryLable={
                                'Claim is marked as submitted successfully'
                            }
                        />
                    )}
                </CustomDialog>
            </div>
            <TablePagination
                pagination={pagination}
                setPagination={setPagination}
            />
            {/* {transcriptmodal && (
                <Transcript
                    open={transcriptmodal}
                    onClose={modalTranscriptClose}
                    appointmentId={task?.appointmentId}
                    scheduleId={task?.scheduleId}
                />
            )} */}
            {/* {fillablePdfmodal && (
                <FillablePdf
                    task={task}
                    open={fillablePdfmodal}
                    onClose={modalTranscriptClose}
                    appointmentId={task?.appointmentId}
                    scheduleId={task?.scheduleId}
                />
            )} */}
            {/* {document3PdfModel && (
                <Document3
                    open={document3PdfModel}
                    onClose={modalTranscriptClose}
                    appointmentId={task?.appointmentId}
                    scheduleId={task?.scheduleId}
                />
            )} */}
        </div>
    )
}
export default JobManagerQ
