import api from './index'

// export const getEncounters = async (
//     providerId: string,
//     appointmentId: string,
// ) => {
//     try {
//         const payload = await api.get(
//             `task/encounters/${providerId}/${appointmentId}`,
//         )
//         if (payload) return payload
//     } catch (error) {
//         return error
//     }
// }

export const uploadCodeQ = (data: any) =>
    api.handleApiRequest(() => api.post('encounter/uploadCodeQ', data))
