import React from 'react'
import { ReactComponent as Taskselected } from '../Assests/Icons/Task selected.svg'
import { ReactComponent as Setting } from '../Assests/Icons/Settings.svg'
import { ReactComponent as Auditor } from '../Assests/Icons/Auditor.svg'
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AuditorManagerDashboard from './AuditorManager/AuditorManagerDashboard/AuditorManagerDashboard'
import TATMeterList from './AuditorManager/TATMeterList/TATMeterList'
import TATMeterAuditorIndividual from './AuditorManager/TATMeterList/IndividualAuditor/TATMeterAuditorIndividual'
import { Route } from 'react-router-dom'
import ManagerUserManagement from './AuditorManager/UserManagement/ManagerUserManagement'
import ManagerReportHome from './AuditorManager/ManagerReports/ManagerReportHome'
import { useSelector } from 'react-redux'
import JobManagerQ from './TaskManager/JobManagerQ'

const WorkloadManager = React.lazy(
    () => import('./WorkloadManager/WorkloadManager'),
)
const Workload = React.lazy(() => import('./Workload/Workload'))
const Auditors = React.lazy(() => import('./Auditors/Auditors'))
const Settings = React.lazy(() => import('./Settings/Settings'))
const JobManager = React.lazy(() => import('./TaskManager/JobManager'))
const AuditorsManager = React.lazy(
    () => import('./AuditorsAdmin/AuditorsAdmin'),
)
const Coding = React.lazy(() => import('./Coding/Coding'))
const Shifts = React.lazy(() => import('./Shifts/Shifts'))
const CodingClean = React.lazy(() => import('./Coding/CodingClean'))
const AuditorTat = React.lazy(() => import('./AuditorTAT/AuditorTAT'))
const PhraseRecorder = React.lazy(
    () => import('./PhraseRecorder/PhraseRecorder'),
)
export const routerArr = [
    {
        id: 1,
        path: '/jobmanager',
        label: 'Job Manager',
        component: <JobManager />,
        icon: <Taskselected />,
        roles: ['auditoradmin', 'auditormanager', 'auditor'],
        permission: ['auditormanager', 'auditoradmin', 'auditor'],
        purchased: ['CP', 'VP'],
    },
    {
        id: 2,
        path: '/jobmanagerq',
        label: 'Q Job Manager',
        component: <JobManagerQ />,
        icon: <Taskselected />,
        roles: ['auditoradmin', 'auditormanager', 'auditor'],
        permission: ['auditormanager', 'auditoradmin', 'auditor'],
        purchased: ['ClaimsQ', 'CodeQ'],
    },
    {
        id: 3,
        path: '/workload',
        label: 'Workload',
        component: <WorkloadManager />,
        icon: <Taskselected />,
        roles: ['auditoradmin', 'auditormanager'],
        permission: ['auditormanager', 'auditoradmin'],
        purchased: ['CP', 'VP', 'ClaimsQ', 'CodeQ'],
    },
    // {
    //     id: 4,
    //     path: '/workload-detail',
    //     label: 'Workload Detail',
    //     component: <Workload />,
    //     icon: <Taskselected />,
    //     roles: ['auditoradmin', 'auditormanager', 'auditor'],
    //     permission: ['auditormanager', 'auditoradmin', 'auditor'],
    // },
    {
        id: 5,
        path: '/auditors',
        label: 'Auditors',
        component: <Auditors />,
        icon: <Auditor />,
        roles: ['auditoradmin', 'auditormanager', 'auditor'],
        permission: ['auditormanager', 'auditoradmin'],
        purchased: ['CP', 'VP', 'ClaimsQ', 'CodeQ'],
    },
    {
        id: 6,
        path: '/auditorsManager',
        label: 'Auditors Manager',
        component: <AuditorsManager />,
        icon: <Auditor />,
        roles: ['auditoradmin', 'auditormanager', 'auditor'],
        permission: ['auditoradmin'],
        purchased: ['CP', 'VP', 'ClaimsQ', 'CodeQ'],
    },
    // {
    //     id: 7,
    //     path: '/settings',
    //     label: 'Settings',
    //     component: <Settings />,
    //     icon: <Setting />,
    //     roles: ['auditoradmin', 'auditormanager', 'auditor'],
    //     permission: ['auditormanager', 'auditoradmin', 'auditor'],
    // },
    {
        id: 8,
        path: '/coding',
        label: 'Coding',
        component: <Coding />,
        icon: <Auditor />,
        roles: ['auditoradmin', 'auditormanager', 'auditor'],
        permission: ['auditormanager', 'auditoradmin', 'auditor'],
        purchased: ['CP', 'VP', 'ClaimsQ', 'CodeQ'],
    },
    // {
    //     id: 9,
    //     path: '/phraserecord',
    //     label: 'Phrase Recorder',
    //     component: <PhraseRecorder />,
    //     icon: <KeyboardVoiceIcon />,
    //     roles: ['auditoradmin', 'auditormanager', 'auditor'],
    //     permission: ['auditormanager', 'auditoradmin', 'auditor'],
    // },
    {
        id: 10,
        path: '/auditortat',
        label: 'Auditor TAT',
        component: <AuditorTat />,
        icon: <AccessTimeIcon />,
        roles: ['auditoradmin', 'auditormanager'],
        permission: ['auditormanager', 'auditoradmin'],
        purchased: ['CP', 'VP', 'ClaimsQ', 'CodeQ'],
    },
    {
        id: 12,
        path: '/shifts',
        label: 'Shifts',
        component: <Shifts />,
        icon: <Auditor />,
        roles: ['auditoradmin', 'auditormanager', 'auditor'],
        permission: ['auditormanager', 'auditoradmin', 'auditor'],
        purchased: ['CP', 'VP', 'ClaimsQ', 'CodeQ'],
    },
    {
        id: 13,
        path: '/AuditorManager',
        label: 'Auditor Manager',
        component: <AuditorManagerDashboard />,
        icon: <Auditor />,
        roles: ['auditoradmin', 'auditormanager'],
        permission: ['auditormanager', 'auditoradmin'],
        purchased: ['CP', 'VP', 'ClaimsQ', 'CodeQ'],
    },
    {
        id: 14,
        path: '/usermanagement',
        label: 'User Management',
        component: <ManagerUserManagement />,
        icon: <Auditor />,
        roles: ['auditormanager'],
        permission: ['auditormanager'],
        purchased: ['CP', 'VP', 'ClaimsQ', 'CodeQ'],
    },
    {
        id: 15,
        path: '/reports',
        label: 'Reports',
        component: <ManagerReportHome />,
        icon: <Auditor />,
        roles: ['auditormanager'],
        permission: ['auditormanager'],
        purchased: ['CP', 'VP', 'ClaimsQ', 'CodeQ'],
    },

    {
        id: 16,
        path: '/AuditorManager/TATMeterList',
        label: 'Jobs By Auditor',
        component: <TATMeterList />,
        icon: <Auditor />,
        roles: ['auditoradmin', 'auditormanager'],
        permission: ['auditormanager', 'auditoradmin'],
        purchased: ['CP', 'VP', 'ClaimsQ', 'CodeQ'],
    },
    {
        id: 17,
        path: '/AuditorManager/TATMeterList/:id',
        label: 'Jobs By Auditor',
        component: <TATMeterAuditorIndividual />,
        // component: <TATMeterList />,
        // icon: <Auditor />,
        roles: ['auditoradmin', 'auditormanager'],
        permission: ['auditormanager', 'auditoradmin'],
        purchased: ['CP', 'VP', 'ClaimsQ', 'CodeQ'],
    },
]
