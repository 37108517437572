import React, { useState } from 'react'
import CodeQLogo from '../../../Assests/CodeQLogo.png'
import ClaimsQLogo from '../../../Assests/ClaimsQLogo.png'
import CPLogo from '../../../Assests/CPLogo.png'
import VPLogo from '../../../Assests/VPLogo.png'
import ClaimsCodeQLogo from '../../../Assests/ClaimsCodeLogo.png'
import styles from './LogoView.module.css'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'

interface LogoViewProps {
    userConfig?: any
    selected?: any
    setSelected?: any
    from?: any
    path?: any
}
function LogoView(props: LogoViewProps) {
    const navigate = useNavigate()
    const { userConfig, from, path } = props
    // if (props.selected == '') {
    //     props.setSelected = userConfig[0]
    // }
    const userArray = userConfig?.split(',')
    // const userArray = ['ClaimsQ', 'CodeQ', 'CP']
    const valuesToCheck = ['ClaimsQ', 'CodeQ']
    const intersection = _.intersection(userArray, valuesToCheck)
    // const [selected, setSelected] = useState(userArray[0])
    console.log('psth', path)
    const LogoSwitch = () => {
        switch (userArray.length) {
            case 1:
                if (userArray[0] == 'CP') {
                    return (
                        <img
                            src={CPLogo}
                            style={{
                                width: '8dvw',
                            }}
                        />
                    )
                } else if (userArray[0] == 'VP') {
                    return (
                        <img
                            src={VPLogo}
                            style={{
                                width: '8dvw',
                            }}
                        />
                    )
                } else if (userArray[0] == 'ClaimsQ') {
                    return (
                        <img
                            src={ClaimsQLogo}
                            style={{
                                width: '25dvh',
                            }}
                        />
                    )
                } else if (userArray[0] == 'CodeQ') {
                    return (
                        <img
                            src={CodeQLogo}
                            style={{
                                width: '25dvh',
                            }}
                        />
                    )
                }
                return <img src={userArray[0] + 'Logo'} />
            case 2:
                if (
                    !_.isEmpty(
                        _.includes(userArray, 'CodeQ') &&
                            _.includes(userArray, 'ClaimsQ'),
                    )
                ) {
                    return (
                        <img
                            src={ClaimsCodeQLogo}
                            style={{
                                width: '15dvw',
                            }}
                        />
                    )
                } else {
                    return userArray.map((names: any, index: number) => {
                        if (names == 'CP') {
                            return (
                                <img
                                    className={
                                        props.selected == names
                                            ? styles.selected
                                            : styles.notselected
                                    }
                                    style={{
                                        width: '8dvw',
                                    }}
                                    onClick={() => props.setSelected(names)}
                                    key={index}
                                    src={CPLogo}
                                    draggable={false}
                                />
                            )
                        } else if (names == 'VP') {
                            return (
                                <img
                                    style={{
                                        width: '15dvh',
                                    }}
                                    onClick={() => props.setSelected(names)}
                                    key={index}
                                    src={VPLogo}
                                    draggable={false}
                                />
                            )
                        } else if (names == 'ClaimsQ') {
                            return (
                                <img
                                    className={
                                        props.selected == names
                                            ? styles.selected
                                            : styles.notselected
                                    }
                                    style={{
                                        width: '15dvw',
                                        opacity: 'selected == names',
                                    }}
                                    onClick={() => props.setSelected(names)}
                                    key={index}
                                    src={ClaimsQLogo}
                                    draggable={false}
                                />
                            )
                        } else if (names == 'CodeQ') {
                            return (
                                <img
                                    className={
                                        props.selected == names
                                            ? styles.selected
                                            : styles.notselected
                                    }
                                    onClick={() => props.setSelected(names)}
                                    style={{
                                        width: '15dvw',
                                    }}
                                    key={index}
                                    src={CodeQLogo}
                                    draggable={false}
                                />
                            )
                        }
                    })
                }
            case 3: {
                if (!_.isEmpty(intersection)) {
                    const result = _.difference(userArray, valuesToCheck)
                    return (
                        <>
                            <img
                                className={
                                    props.selected == 'ClaimsQ' ||
                                    props.selected == 'CodeQ'
                                        ? styles.selected
                                        : styles.notselected
                                }
                                onClick={() => props.setSelected('ClaimsQ')}
                                src={ClaimsCodeQLogo}
                                style={{
                                    width: '15dvw',
                                }}
                            />
                            {result[0] == 'CP' ? (
                                <img
                                    style={{
                                        width: '8dvw',
                                    }}
                                    className={
                                        props.selected == result[0]
                                            ? styles.selected
                                            : styles.notselected
                                    }
                                    onClick={() => props.setSelected('CP')}
                                    src={CPLogo}
                                    draggable={false}
                                />
                            ) : (
                                <img
                                    style={{
                                        width: '8dvw',
                                    }}
                                    onClick={() => props.setSelected('VP')}
                                    src={VPLogo}
                                    draggable={false}
                                />
                            )}
                        </>
                    )
                }
            }
        }
    }
    const LogoSwitch2 = () => {
        switch (userArray.length) {
            case 1:
                if (userArray[0] == 'CP') {
                    return (
                        <img
                            src={CPLogo}
                            style={{ height: 30, padding: '0px 0px 0px 30px' }}
                        />
                    )
                } else if (userArray[0] == 'VP') {
                    return (
                        <img
                            src={VPLogo}
                            style={{ height: 30, padding: '0px 0px 0px 30px' }}
                        />
                    )
                } else if (userArray[0] == 'ClaimsQ') {
                    return (
                        <img
                            src={ClaimsQLogo}
                            style={{ height: 30, padding: '0px 0px 0px 30px' }}
                        />
                    )
                } else if (userArray[0] == 'CodeQ') {
                    return (
                        <img
                            src={CodeQLogo}
                            style={{ height: 30, padding: '0px 0px 0px 30px' }}
                        />
                    )
                }
                return <img src={userArray[0] + 'Logo'} />
            case 2:
                if (
                    !_.isEmpty(
                        _.includes(userArray, 'CodeQ') &&
                            _.includes(userArray, 'ClaimsQ'),
                    )
                ) {
                    return (
                        <img
                            src={ClaimsCodeQLogo}
                            style={{ height: 30, padding: '0px 0px 0px 30px' }}
                        />
                    )
                } else {
                    return userArray.map((names: any, index: number) => {
                        if (names == 'CP') {
                            return (
                                <img
                                    className={
                                        path == '/jobmanager'
                                            ? styles.selected
                                            : styles.notselected
                                    }
                                    style={{
                                        height: 30,
                                        padding: '0px 0px 0px 30px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => navigate('/jobmanager')}
                                    key={index}
                                    src={CPLogo}
                                    draggable={false}
                                />
                            )
                        } else if (names == 'VP') {
                            return (
                                <img
                                    className={
                                        props.path == '/jobmanager'
                                            ? styles.selected
                                            : styles.notselected
                                    }
                                    style={{
                                        height: 30,
                                        padding: '0px 0px 0px 30px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => navigate('/jobmanager')}
                                    key={index}
                                    src={VPLogo}
                                    draggable={false}
                                />
                            )
                        } else if (names == 'ClaimsQ') {
                            return (
                                <img
                                    className={
                                        props.path == '/jobmanagerq'
                                            ? styles.selected
                                            : styles.notselected
                                    }
                                    style={{
                                        height: 30,
                                        padding: '0px 0px 0px 30px',
                                        cursor: 'pointer',
                                    }}
                                    // onClick={() => props.setSelected(names)}
                                    onClick={() => navigate('/jobmanagerq')}
                                    key={index}
                                    src={ClaimsQLogo}
                                    draggable={false}
                                />
                            )
                        } else if (names == 'CodeQ') {
                            return (
                                <img
                                    className={
                                        props.path == '/jobmanagerq'
                                            ? styles.selected
                                            : styles.notselected
                                    }
                                    style={{
                                        height: 30,
                                        padding: '0px 0px 0px 30px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => navigate('/jobmanagerq')}
                                    key={index}
                                    src={CodeQLogo}
                                    draggable={false}
                                />
                            )
                        }
                    })
                }
            case 3: {
                if (!_.isEmpty(intersection)) {
                    const result = _.difference(userArray, valuesToCheck)
                    return (
                        <>
                            <img
                                className={
                                    props.path == '/jobmanagerq'
                                        ? styles.selected
                                        : styles.notselected
                                }
                                onClick={() => navigate('/jobmanagerq')}
                                src={ClaimsCodeQLogo}
                                style={{
                                    height: 30,
                                    padding: '0px 0px 0px 30px',
                                    cursor: 'pointer',
                                }}
                            />
                            {result[0] == 'CP' ? (
                                <img
                                    style={{
                                        height: 30,
                                        padding: '0px 0px 0px 30px',
                                        cursor: 'pointer',
                                    }}
                                    className={
                                        props.path == '/jobmanager'
                                            ? styles.selected
                                            : styles.notselected
                                    }
                                    onClick={() => navigate('/jobmanager')}
                                    // onClick={() => props.setSelected(names)}

                                    src={CPLogo}
                                    draggable={false}
                                />
                            ) : (
                                <img
                                    style={{
                                        height: 30,
                                        padding: '0px 0px 0px 30px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => navigate('/jobmanager')}
                                    // onClick={() => props.setSelected('VP')}
                                    src={VPLogo}
                                    draggable={false}
                                />
                            )}
                        </>
                    )
                }
            }
        }
    }
    return from == null ? <div>{LogoSwitch()}</div> : <div>{LogoSwitch2()}</div>
}

export default LogoView
