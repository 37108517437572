import React from 'react'
import { getSettings } from '../api/settingAPIService'
import { settingsOptions } from '../utils/constant'
import { useSelector } from 'react-redux'

export function useTableService(from?: any) {
    const FIELDREGEX = /\d+/
    const [selectField, setSelectField] = React.useState<any>(() => {
        if (from?.from == 'attention') {
            return {
                field1: {
                    id: 1,
                    key: 'status',
                    readonly: true,
                    fil: true,
                    label: 'Status',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field2: {
                    id: 2,
                    key: 'auditorName',
                    readonly: false,
                    fil: true,
                    label: 'Auditor',
                    roles: ['auditoradmin', 'auditormanager'],
                },
                field3: {
                    id: 3,
                    key: 'providerName',
                    readonly: false,
                    fil: true,
                    label: 'Provider',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field4: {
                    id: 4,
                    key: 'patientName',
                    readonly: false,
                    fil: true,
                    label: 'Patient',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field5: {
                    id: 5,
                    key: 'taskName',
                    readonly: false,
                    fil: true,
                    label: 'Reason for Visit',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field6: {
                    id: 6,
                    key: 'site',
                    readonly: false,
                    fil: true,
                    label: 'Site of Service',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },

                field7: {
                    id: 7,
                    key: 'TATtime',
                    readonly: false,
                    fil: false,
                    label: 'TAT',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field12: null,
            }
        } else if (from?.from == 'TATMeterAuditor') {
            return {
                field1: {
                    id: 1,
                    key: 'firstName',
                    readonly: true,
                    fil: true,
                    label: 'First Name',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field2: {
                    id: 2,
                    key: 'lastName',
                    readonly: false,
                    fil: true,
                    label: 'Last Name',
                    roles: ['auditoradmin', 'auditormanager'],
                },
                field3: {
                    id: 3,
                    key: 'providerName',
                    readonly: false,
                    fil: true,
                    label: 'Provider',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field4: {
                    id: 4,
                    key: 'PrimSpecialty',
                    readonly: false,
                    fil: true,
                    label: 'Prim. Specialty',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field5: {
                    id: 5,
                    key: 'SecSpecialty',
                    readonly: false,
                    fil: true,
                    label: 'Sec. Specialty',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field6: {
                    id: 6,
                    key: 'totalJobs',
                    readonly: false,
                    fil: true,
                    label: 'Total Jobs',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },

                field8: {
                    id: 8,
                    key: 'lastLoginTime',
                    readonly: false,
                    fil: false,
                    label: 'Last Login Time',
                    roles: ['auditoradmin', 'auditormanager'],
                },
                field9: {
                    id: 9,
                    key: 'averageAuditorTAT',
                    readonly: false,
                    fil: false,
                    label: 'Average Auditor TAT',
                    roles: ['auditoradmin', 'auditormanager'],
                },
            }
        } else if (from?.from == 'TATMeterProvider') {
            return {
                field1: {
                    id: 1,
                    key: 'providerName',
                    readonly: true,
                    fil: true,
                    label: 'Provider Name',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field2: {
                    id: 2,
                    key: 'contractClient',
                    readonly: false,
                    fil: true,
                    label: 'Contract Client',
                    roles: ['auditoradmin', 'auditormanager'],
                },
                field3: {
                    id: 3,
                    key: 'cityState',
                    readonly: false,
                    fil: true,
                    label: 'City, State',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field4: {
                    id: 4,
                    key: 'siteOfService',
                    readonly: false,
                    fil: true,
                    label: 'Site of Service',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field5: {
                    id: 5,
                    key: 'specialty',
                    readonly: false,
                    fil: true,
                    label: 'Specialty',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field6: {
                    id: 6,
                    key: 'assignedAuditor',
                    readonly: false,
                    fil: true,
                    label: 'Assigned Auditors',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },

                field8: {
                    id: 8,
                    key: 'numRecordingsSubmitted',
                    readonly: false,
                    fil: false,
                    label: '# Recordings Submitted',
                    roles: ['auditoradmin', 'auditormanager'],
                },
                field9: {
                    id: 9,
                    key: 'averageAuditorTAT',
                    readonly: false,
                    fil: false,
                    label: 'Average Auditor TAT',
                    roles: ['auditoradmin', 'auditormanager'],
                },
            }
        } else if (from?.from == 'IndividualAuditor') {
            return {
                field1: {
                    id: 1,
                    key: 'check',
                    // readonly: true,
                    // fil: true,
                    label: '',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field2: {
                    id: 2,
                    key: 'status',
                    readonly: true,
                    fil: true,
                    label: 'Status',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field3: {
                    id: 3,
                    key: 'location',
                    readonly: false,
                    fil: true,
                    label: 'Contract Client',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field4: {
                    id: 4,
                    key: 'city',
                    readonly: false,
                    fil: true,
                    label: 'City, State',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field5: {
                    id: 5,
                    key: 'providerName',
                    readonly: false,
                    fil: true,
                    label: 'Provider',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field6: {
                    id: 6,
                    key: 'site',
                    readonly: false,
                    fil: true,
                    label: 'Site',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field7: {
                    id: 7,
                    key: 'startDate',
                    readonly: false,
                    fil: false,
                    label: 'Encounter Date',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field8: {
                    id: 8,
                    key: 'taskName',
                    readonly: false,
                    fil: true,
                    label: 'Reason for Visit',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field9: {
                    id: 9,
                    key: 'createdAt',
                    readonly: false,
                    fil: true,
                    label: 'Audio Received',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field10: {
                    id: 10,
                    key: 'galenTime',
                    readonly: false,
                    fil: false,
                    label: 'Galen',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field11: {
                    id: 11,
                    key: 'auditorTAT',
                    readonly: false,
                    fil: false,
                    label: 'Auditor TAT',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field12: {
                    id: 12,
                    key: 'totalTAT',
                    readonly: false,
                    fil: false,
                    label: 'Total TAT',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field13: {
                    id: 13,
                    key: 'comment',
                    label: 'Comment',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
            }
        } else if (from?.from == 'ManagerUM') {
            return {
                field1: {
                    id: 1,
                    key: 'check',
                    // readonly: true,
                    // fil: true,
                    label: '',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                    sort: false,
                },
                field2: {
                    id: 2,
                    key: 'userName',
                    readonly: true,
                    fil: true,
                    label: 'User Name',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field3: {
                    id: 3,
                    key: 'firstName',
                    readonly: false,
                    fil: true,
                    label: 'First Name',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field4: {
                    id: 4,
                    key: 'lastName',
                    readonly: false,
                    fil: true,
                    label: 'Last Name',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field5: {
                    id: 5,
                    key: 'email',
                    readonly: false,
                    fil: true,
                    label: 'Email Address',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field6: {
                    id: 6,
                    key: 'mobile',
                    readonly: false,
                    fil: true,
                    label: 'Mobile',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field7: {
                    id: 7,
                    key: 'primSpecialty',
                    readonly: false,
                    fil: false,
                    label: 'Prim. Specialty',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field8: {
                    id: 8,
                    key: 'secSpecialty',
                    readonly: false,
                    fil: true,
                    label: 'Sec. Specialty',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field9: {
                    id: 9,
                    key: 'assignedProvider',
                    readonly: false,
                    fil: true,
                    label: 'Assigned Provider',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field10: {
                    id: 10,
                    key: 'dateCreated',
                    readonly: false,
                    fil: false,
                    label: 'Date Created',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field11: {
                    id: 11,
                    key: 'workHours',
                    readonly: false,
                    fil: false,
                    label: 'Work Hours',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field12: {
                    id: 12,
                    key: 'lastLogin',
                    readonly: false,
                    fil: false,
                    label: 'Last Login',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field13: {
                    id: 13,
                    key: 'edit',
                    label: '',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                    sort: false,
                },
            }
        } else if (from?.from == 'JobReport') {
            return {
                field1: {
                    id: 1,
                    key: 'jobID',
                    // readonly: true,
                    // fil: true,
                    label: 'Job ID',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                    // sort: false,
                },
                field2: {
                    id: 2,
                    key: 'status',
                    readonly: true,
                    fil: true,
                    label: 'Status',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field3: {
                    id: 3,
                    key: 'auditorName',
                    readonly: false,
                    fil: true,
                    label: 'Auditor',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field4: {
                    id: 4,
                    key: 'providerName',
                    readonly: false,
                    fil: true,
                    label: 'Provider Name',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field5: {
                    id: 5,
                    key: 'primSpecialty',
                    readonly: false,
                    fil: true,
                    label: 'Specialty',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field6: {
                    id: 6,
                    key: 'patientName',
                    readonly: false,
                    fil: true,
                    label: 'Patient Name',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field7: {
                    id: 7,
                    key: 'audioReceived',
                    readonly: false,
                    fil: false,
                    label: 'Audio Received',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field8: {
                    id: 8,
                    key: 'audioLength',
                    readonly: false,
                    fil: true,
                    label: 'Audio Length',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field9: {
                    id: 9,
                    key: 'currentStatusTime',
                    readonly: false,
                    fil: true,
                    label: 'Time in Current Status',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field10: {
                    id: 10,
                    key: 'galenTime',
                    readonly: false,
                    fil: false,
                    label: 'Galen Time',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field11: {
                    id: 11,
                    key: 'auditorTAT',
                    readonly: false,
                    fil: false,
                    label: 'Auditor TAT',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field12: {
                    id: 12,
                    key: 'totalTAT',
                    readonly: false,
                    fil: false,
                    label: 'Total TAT',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
            }
        } else if (from?.from == 'UserActivity') {
            return {
                field1: {
                    id: 1,
                    key: 'userName',
                    readonly: true,
                    fil: true,
                    label: 'User Name',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                    // sort: false,
                },
                field2: {
                    id: 2,
                    key: 'firstName',
                    readonly: true,
                    fil: true,
                    label: 'First Name',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field3: {
                    id: 3,
                    key: 'lastName',
                    readonly: false,
                    fil: true,
                    label: 'Last Name',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field4: {
                    id: 4,
                    key: 'lastLogin',
                    readonly: false,
                    fil: true,
                    label: 'Last Login Time',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field5: {
                    id: 5,
                    key: 'lastIdle',
                    readonly: false,
                    fil: true,
                    label: 'Last Idle Time',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
            }
        } else if (from?.from == 'OnHoldReport') {
            return {
                field1: {
                    id: 1,
                    key: 'jobID',
                    // readonly: true,
                    // fil: true,
                    label: 'Job ID',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                    // sort: false,
                },
                field2: {
                    id: 2,
                    key: 'jobCreatedDate',
                    readonly: true,
                    fil: true,
                    label: 'Job Created Date',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field3: {
                    id: 3,
                    key: 'providerName',
                    readonly: false,
                    fil: true,
                    label: 'Provider',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field4: {
                    id: 4,
                    key: 'auditorName',
                    readonly: false,
                    fil: true,
                    label: 'Auditor',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field5: {
                    id: 5,
                    key: 'dateTimeSet',
                    readonly: false,
                    fil: true,
                    label: 'Date & Time Job set to on-hold',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field6: {
                    id: 6,
                    key: 'userSetHold',
                    readonly: false,
                    fil: true,
                    label: 'User who set the on-hold status',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field7: {
                    id: 7,
                    key: 'dateTimeRemoved',
                    readonly: false,
                    fil: false,
                    label: 'Date & Time Job removed on-hold',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field8: {
                    id: 8,
                    key: 'userRemoved',
                    readonly: false,
                    fil: true,
                    label: 'User who removed the on-hold status',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field9: {
                    id: 9,
                    key: 'jobStatusChangedTo',
                    readonly: false,
                    fil: true,
                    label: 'Job Status Changed To',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field10: {
                    id: 10,
                    key: 'totalHoldTime',
                    readonly: false,
                    fil: false,
                    label: 'Total Hold Time',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
            }
        } else if (from?.from == 'ProviderReport') {
            return {
                field1: {
                    id: 1,
                    key: 'providerName',
                    // readonly: true,
                    // fil: true,
                    label: 'Provider',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                    // sort: false,
                },
                field2: {
                    id: 2,
                    key: 'startDate',
                    readonly: true,
                    fil: true,
                    label: 'Start Date',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field3: {
                    id: 3,
                    key: 'specialty',
                    readonly: false,
                    fil: true,
                    label: 'Specialty',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field4: {
                    id: 4,
                    key: 'NPI',
                    readonly: false,
                    fil: true,
                    label: 'NPI',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field5: {
                    id: 5,
                    key: 'orgName',
                    readonly: false,
                    fil: true,
                    label: 'Org Name',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field6: {
                    id: 6,
                    key: 'orgTIN',
                    readonly: false,
                    fil: true,
                    label: 'Org Tin',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field7: {
                    id: 7,
                    key: 'cityState',
                    readonly: false,
                    fil: false,
                    label: 'City, State',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field8: {
                    id: 8,
                    key: 'medicareJurisdiction',
                    readonly: false,
                    fil: true,
                    label: 'Medicare Jurisdiction',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field9: {
                    id: 9,
                    key: 'assignedAuditors',
                    readonly: false,
                    fil: true,
                    label: 'Assigned Auditors',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field10: {
                    id: 10,
                    key: 'totalRecordings',
                    readonly: false,
                    fil: false,
                    label: 'Total Recordings Submitted',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field11: {
                    id: 11,
                    key: 'dailyAvg',
                    readonly: false,
                    fil: false,
                    label: 'Daily AVG of Submissions',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field12: {
                    id: 12,
                    key: 'activityStatus',
                    readonly: false,
                    fil: false,
                    label: 'Status',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
            }
        } else if (from?.from == 'Q') {
            return {
                field1: {
                    id: 1,
                    key: 'status',
                    readonly: true,
                    fil: true,
                    label: 'Status',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field2: {
                    id: 2,
                    key: 'location',
                    readonly: false,
                    fil: true,
                    label: 'Contract Client',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field3: {
                    id: 3,
                    key: 'siteOfService',
                    readonly: false,
                    fil: true,
                    label: 'Site of Service',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field4: {
                    id: 4,
                    key: 'cityState',
                    readonly: false,
                    fil: true,
                    label: 'City, State',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },

                field5: {
                    id: 5,
                    key: 'cqProviderName',
                    readonly: false,
                    fil: true,
                    label: 'Provider Name',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field6: {
                    id: 6,
                    key: 'appointmentId',
                    readonly: false,
                    fil: true,
                    label: 'Visit Number',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },

                field7: {
                    id: 7,
                    key: 'startDate',
                    readonly: false,
                    fil: false,
                    label: 'Encounter Date',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field8: {
                    id: 8,
                    key: 'taskName',
                    readonly: false,
                    fil: true,
                    label: 'Reason for Stay',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
            }
        } else if (from?.from == 'A') {
            return {
                field1: {
                    id: 1,
                    key: 'status',
                    readonly: true,
                    fil: true,
                    label: 'Status',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field2: {
                    id: 2,
                    key: 'location',
                    readonly: false,
                    fil: true,
                    label: 'Contract Client',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field3: {
                    id: 3,
                    key: 'cityState',
                    readonly: false,
                    fil: true,
                    label: 'City, State',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field4: {
                    id: 4,
                    key: 'auditorName',
                    readonly: false,
                    fil: true,
                    label: 'Auditor Name',
                    roles: ['auditoradmin', 'auditormanager'],
                },
                field5: {
                    id: 5,
                    key: 'cqProviderName',
                    readonly: false,
                    fil: true,
                    label: 'Provider Name',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field6: {
                    id: 6,
                    key: 'patientName',
                    readonly: false,
                    fil: true,
                    label: 'Patient Name',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field7: {
                    id: 7,
                    key: 'siteOfService',
                    readonly: false,
                    fil: true,
                    label: 'Site of Service',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field8: {
                    id: 8,
                    key: 'startDate',
                    readonly: false,
                    fil: false,
                    label: 'Encounter Date',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field9: {
                    id: 9,
                    key: 'taskName',
                    readonly: false,
                    fil: true,
                    label: 'Reason for Visit',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field10: {
                    id: 10,
                    key: 'audioReceived',
                    readonly: false,
                    fil: true,
                    label: 'Audio Received',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field11: {
                    id: 11,
                    key: 'TATtime',
                    readonly: false,
                    fil: false,
                    label: 'TAT',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
            }
        } else {
            return {
                field1: {
                    id: 1,
                    key: 'status',
                    readonly: true,
                    fil: true,
                    label: 'Status',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field2: {
                    id: 2,
                    key: 'location',
                    readonly: false,
                    fil: true,
                    label: 'Contract Client',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field3: {
                    id: 3,
                    key: 'cityState',
                    readonly: false,
                    fil: true,
                    label: 'City, State',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field4: {
                    id: 4,
                    key: 'auditorName',
                    readonly: false,
                    fil: true,
                    label: 'Auditor Name',
                    roles: ['auditoradmin', 'auditormanager'],
                },
                field5: {
                    id: 5,
                    key: 'cqProviderName',
                    readonly: false,
                    fil: true,
                    label: 'Provider Name',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field6: {
                    id: 6,
                    key: 'patientName',
                    readonly: false,
                    fil: true,
                    label: 'Patient Name',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field7: {
                    id: 7,
                    key: 'siteOfService',
                    readonly: false,
                    fil: true,
                    label: 'Site of Service',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
                field8: {
                    id: 8,
                    key: 'startDate',
                    readonly: false,
                    fil: false,
                    label: 'Encounter Date',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field9: {
                    id: 9,
                    key: 'taskName',
                    readonly: false,
                    fil: true,
                    label: 'Reason for Visit',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field10: {
                    id: 10,
                    key: 'audioReceived',
                    readonly: false,
                    fil: true,
                    label: 'Audio Received',
                    roles: ['auditoradmin', 'auditormanager', 'auditor'],
                },
                field11: {
                    id: 11,
                    key: 'TATtime',
                    readonly: false,
                    fil: false,
                    label: 'TAT',
                    roles: ['auditor', 'auditoradmin', 'auditormanager'],
                },
            }
        }
    })
    // const [selectField, setSelectField] = React.useState<any>({
    //     field1: null,
    //     field2: null,
    //     field3: null,
    //     field4: null,
    //     field5: null,
    //     field6: null,
    //     field7: null,
    //     field8: null,
    //     field9: null,
    //     field10: null,
    //     field11: null,
    //     field12: null,
    // })
    const userId = useSelector(
        (state: any) => state.persistReducers?.userReducer?.user?.id,
    )
    // React.useEffect(() => {
    //     async function getList() {
    //         setGetList()
    //     }
    //     getList()
    // }, [])
    // const setGetList = async () => {
    //     if (userId) {
    //         const result = await getSettings()
    //         const res: any = {
    //             // appointmentType: null,
    //             // audio: null,
    //             // auditorId: 'b06eed8a-ac06-427d-90bc-84ae9656358d',
    //             // comment: null,
    //             // default: true,
    //             // encounterCollection: null,
    //             // endDate: null,
    //             // id: '7631 6c52-83a5-4c8e-90df-33671eda459f',

    //             // message: null,
    //             // patientAccount: null,
    //             // speciality: null,
    //             status: 1,
    //             location: 2,
    //             city: 3,
    //             auditorName: 4,
    //             providerName: 5,
    //             patientName: 6,
    //             site: 7,
    //             startDate: 8,
    //             taskName: 9,
    //             audioReceived: 10,
    //             TATtime: 11,
    //         }
    //         const data: any = []
    //         for (const key in res) {
    //             const obj = settingsOptions.find(
    //                 (item: any) => item.key === key,
    //             )
    //             if (obj) {
    //                 obj.id = res[key]
    //                 data.push(obj)
    //             }
    //         }
    //         if (data?.length >= 1) {
    //             for (const iterator in selectField) {
    //                 setSelectField((pre: any) => {
    //                     const num: any = iterator.match(FIELDREGEX)?.[0]
    //                     return {
    //                         ...pre,
    //                         [iterator]: data.find(
    //                             (item: any) => item.id === parseInt(num),
    //                         ),
    //                     }
    //                 })
    //             }
    //         }
    //     }
    // }

    return { selectField }
}
