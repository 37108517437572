import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

type PrivateRouteProps = {
    children: React.ReactNode
    permission: string[]
    purchased?: string[]
}
export const PrivateRoute = ({
    children,
    permission,
    purchased,
}: PrivateRouteProps) => {
    const navigate = useNavigate()
    const userConfig = useSelector(
        (state: any) => state.persistReducers?.userReducer?.config?.name,
    )
    const userToken = useSelector(
        (state: any) => state.persistReducers?.userReducer?.userToken,
    )
    const user = useSelector(
        (state: any) => state.persistReducers?.userReducer?.user,
    )

    if (
        !userToken ||
        !user?.userRole ||
        !permission?.some((item) => user?.userRole === item)
    ) {
        navigate('/')
    }
    if (
        purchased &&
        !purchased?.some((item) => userConfig?.split(',').includes(item))
    ) {
        navigate(-1)
    }

    return <>{children}</>
}
