import * as React from 'react';
import { Typography, Button, IconButton, Menu, MenuItem, TextField, Tooltip } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ReactComponent as DownArrow } from '../../Assests/Icons/Down Arrow.svg'
import styles from './CustomFilter.module.css';
import typoStyles from '../../Styles/component/Typography.module.css'
import { useSelector } from 'react-redux';
interface Props {
  filterdHeader?: string,
  handleMenuItemClick?: (value: string) => void,
  handleChangeFilter?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void,
  filteredValue?: string,
  selectField: any,
  closeMenu: any
}
const CustomFilter = (props: Props) => {
  const { filteredValue, handleChangeFilter, closeMenu, filterdHeader, handleMenuItemClick , selectField} = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const user = useSelector((state: any) => state.persistReducers?.userReducer?.user);

  return (
    <div className={styles.CustomFilter}>
      <div className={styles.nameDiv}>
        <Typography className={`${typoStyles.typoRobotoBoldprimary314} ${styles.nameDiv}`}>
          {'Filter by'}
        </Typography>
      </div>
      <div style={{ position: "relative" }}>
        <Button
          id="basic-button"
          aria-haspopup="true"
          onClick={handleClick}
          className={styles.btn}
          sx={{ justifyContent: "space-between" }}
          endIcon={<DownArrow />}
        >
          <Typography className={typoStyles.typoRobotoRegoularprimary316}>{filterdHeader}</Typography>
        </Button>
        <div className={styles.iconbuttondiv}>
        <Tooltip title="Close Filter">
          <IconButton size='small' className={styles.iconbtn} onClick={closeMenu}>
            <HighlightOffIcon sx={{ fontSize: "20px" }}  />
          </IconButton>
          </Tooltip>
        </div>
        {selectField?.field1 && <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{
            '& .MuiPaper-root': {
              padding: "8px",
              minWidth:'127px !important'
            }
          }}
        >
          {Object.values(selectField)?.map((item: any) => (
            item && item.roles.some((ro: any) => ro === user?.userRole) && item.fil && filterdHeader !== item.label &&
            <MenuItem
              key={item.id}
              sx={{
                padding: "10px",
              }}
              value={item.label}
              onClick={() => {
                if (handleMenuItemClick) {
                  handleMenuItemClick(item.label)
                  setAnchorEl(null);
                }
              }}
            >
              <div>
                <Typography className={typoStyles.typoRobotoRegoularprimary714}>
                  {item.label}
                </Typography>
              </div>
            </MenuItem>
          ))}
        </Menu>}
      </div>
      <div>
        <TextField
          placeholder="Type here..."
          onChange={handleChangeFilter}
          value={filteredValue}
          sx={{
            height: '34px',
            width: '127px',
            '& input::placeholder': {
              fontSize: '14px',
              fontStyle: 'italic',
            },
            '& .MuiInputBase-root': {
              height: '34px !important',
              border: '1px solid #D1D1D1',
              borderRadius: '4px',
              minHeight: '34px',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 0,
                borderWidth: 0,
              },
            },
          }}
        />
      </div>
    </div>
  )
}
export default CustomFilter;

