import {
    Box,
    Button,
    IconButton,
    Popover,
    Switch,
    TextField,
} from '@mui/material'
import { Formik, Field, Form, FormikHelpers, ErrorMessage } from 'formik'
import moment from 'moment'
import React, { useState } from 'react'
import EventIcon from '@mui/icons-material/Event'
import { DateRangePicker, DefinedRange } from 'react-date-range'
import enUS from 'date-fns/locale/en-US'
import styles from './NewJobModal.module.css'
import { uploadCodeQ } from '../../../../Services/api/codeQAPIService'

interface UploadTypes {
    contractClient: string
    siteOfService: string
    cityState: string
    providerName: string
    visitNumber: number
    admissionDischarge: RangeSelection
    appointmentReason: string
    chartNoteText?: any
    auditType: string
}
interface RangeSelection {
    startDate?: Date
    endDate?: Date
    key: string
}

export interface NewJobModalProps {
    setToastMessage?: any
    setToast?: any
    handleCloseModal?: any
}
function NewJobModal(props: NewJobModalProps) {
    const { setToastMessage, setToast, handleCloseModal } = props
    const [selectionRange, setSelectionRange] = useState<RangeSelection>({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    })
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null,
    )

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    const handleSelect = (ranges: any) => {
        const newStartDate = new Date(ranges.selection.startDate)
        const newEndDate = new Date(ranges.selection.endDate)
        setSelectionRange(ranges.selection)
    }
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined
    return (
        <Box className={styles.ModalContainer}>
            <div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <DateRangePicker
                        locale={enUS}
                        ranges={[selectionRange]}
                        onChange={handleSelect}
                    />
                </Popover>
                <Formik
                    initialValues={{
                        contractClient: '',
                        siteOfService: '',
                        cityState: '',
                        providerName: '',
                        visitNumber: '0',
                        admissionDischarge: {
                            startDate: new Date(),
                            endDate: new Date(),
                            key: 'selection',
                        },
                        appointmentReason: '',
                        chartNoteText: '',
                        auditType: 'Pre-Bill Coding',
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(async () => {
                            const submitValues = {
                                contractClient: values.contractClient,
                                siteOfService: values.siteOfService,
                                cityState: values.cityState,
                                providerName: values.providerName,
                                visitNumber: values.visitNumber,
                                admissionDischarge: selectionRange,
                                appointmentReason: values.appointmentReason,
                                chartNoteText: values.chartNoteText,
                                auditType: values.auditType,
                            }
                            const uploadResp = await uploadCodeQ(submitValues)
                            if (uploadResp?.statusCode == 201) {
                                handleCloseModal()
                                setToastMessage('good')
                                setToast(true)
                            } else {
                                setToastMessage('bad')
                                setToast(true)
                            }

                            // alert(JSON.stringify(values, null, 2))
                            setSubmitting(false)
                        }, 500)
                    }}
                >
                    {({
                        values,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        errors,
                        /* and other goodies */
                    }) => (
                        <Form
                            style={{ display: 'flex', flexDirection: 'column' }}
                            onSubmit={handleSubmit}
                        >
                            <div className={styles.ModalDiv}>
                                <label className={styles.headerText}>
                                    Contract Client:
                                </label>
                                <TextField
                                    autoFocus
                                    size="small"
                                    id="contractClient"
                                    name="contractClient"
                                    placeholder=""
                                    onChange={handleChange}
                                    sx={{ width: '30dvw' }}
                                />
                            </div>

                            <div className={styles.ModalDiv}>
                                <label
                                    className={styles.headerText}
                                    htmlFor="siteOfService"
                                >
                                    Site of Service:
                                </label>
                                <TextField
                                    size="small"
                                    id="siteOfService"
                                    name="siteOfService"
                                    onChange={handleChange}
                                    sx={{ width: '30dvw' }}
                                />
                            </div>
                            <div className={styles.ModalDiv}>
                                <label
                                    className={styles.headerText}
                                    htmlFor="cityState"
                                >
                                    City, State:{' '}
                                </label>
                                <TextField
                                    size="small"
                                    id="cityState"
                                    name="cityState"
                                    onChange={handleChange}
                                    sx={{ width: '30dvw' }}
                                />
                            </div>
                            <div className={styles.ModalDiv}>
                                <label
                                    className={styles.headerText}
                                    htmlFor="providerName"
                                >
                                    Provider:{' '}
                                </label>
                                <TextField
                                    size="small"
                                    id="providerName"
                                    name="providerName"
                                    onChange={handleChange}
                                    sx={{ width: '30dvw' }}
                                />
                            </div>
                            <div className={styles.ModalDiv}>
                                <label
                                    className={styles.headerText}
                                    htmlFor="visitNumber"
                                >
                                    Visit Number:
                                </label>
                                <TextField
                                    size="small"
                                    id="visitNumber"
                                    name="visitNumber"
                                    onChange={handleChange}
                                    sx={{ width: '30dvw' }}
                                />
                            </div>
                            <div className={styles.ModalDiv}>
                                <label
                                    className={styles.headerText}
                                    htmlFor="admissionDischarge"
                                >
                                    Admission / Discharge Date:
                                </label>
                                <IconButton
                                    aria-describedby={id}
                                    // variant="contained"
                                    onClick={handleClick}
                                >
                                    <EventIcon />
                                </IconButton>
                                <div style={{ display: 'flex' }}>
                                    <TextField
                                        disabled
                                        size="small"
                                        id="admissionDischarge"
                                        name="admissionDischarge"
                                        value={moment(
                                            selectionRange.startDate,
                                        ).format('ll')}
                                        sx={{ width: '100%' }}
                                    />
                                    <TextField
                                        disabled
                                        size="small"
                                        id="admissionDischarge"
                                        name="admissionDischarge"
                                        value={moment(
                                            selectionRange.endDate,
                                        ).format('ll')}
                                        sx={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                            <div className={styles.ModalDiv}>
                                <label
                                    className={styles.headerText}
                                    htmlFor="appointmentReason"
                                >
                                    Reason For Stay:
                                </label>
                                <TextField
                                    size="small"
                                    id="appointmentReason"
                                    name="appointmentReason"
                                    onChange={handleChange}
                                    sx={{ width: '30dvw' }}
                                />
                            </div>
                            <div className={styles.ModalDiv}>
                                <label
                                    className={styles.headerText}
                                    htmlFor="chartNoteText"
                                >
                                    Paste File:
                                </label>
                                <TextField
                                    name="chartNoteText"
                                    id="chartNoteText"
                                    size="small"
                                    onChange={handleChange}
                                    sx={{ width: '30dvw' }}
                                    multiline
                                />

                                {/* <input
                                    type="file"
                                    name="chartNoteText"
                                    accept=".jpg, .jpeg, .png, .gif"
                                    className="block w-full"
                                    onChange={(event: any) => {
                                        console.log(
                                            'event',
                                            event.currentTarget.files,
                                        )
                                        // Set the field value to the selected file
                                        setFieldValue(
                                            'chartNoteText',
                                            event.currentTarget?.files[0],
                                        )
                                    }}
                                /> */}
                                {/* <ErrorMessage
                                    name="image"
                                    component="div"
                                    className="text-red-500"
                                /> */}
                            </div>
                            <div className={styles.ModalDiv}>
                                <label
                                    className={styles.headerText}
                                    htmlFor="auditType"
                                >
                                    Audit Type:{' '}
                                </label>
                                <div role="group" className="toggle__wrapper">
                                    Pre-Bill Coding <Switch /> Post-Bill Audit
                                </div>
                            </div>

                            <Button type="submit" variant="contained">
                                Submit
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </Box>
    )
}

export default NewJobModal
